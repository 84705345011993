@import '../node_modules/@bugsplat/angular-tree-component/css/angular-tree-component.css';
@import 'variables';
@tailwind base;
@tailwind components;
@tailwind utilities;

$dts-primary: lighten($primary, 10);
@import 'ngx-drag-to-select';

:root {
	// main theme darker colours
	--primary-color: #f97316;
	--primary-color2: #067ab2;
	--primary-color3: #05699a;
	--primary-color0: #0EABF7;
	--light-primary-color: rgb(51, 155, 255, 0.15);
	--light-primary-color2: rgb(51, 155, 255, 0.10);

	// main theme lighter accent colorus
	--accent-white: #eff6ff;
	--accent-color1: #bfdbfe;
	--accent-color2: #60a5fa;
}

.ng-placeholder {
	text-transform: capitalize;
}

::placeholder {
	text-transform: capitalize;
}

// This class transforms placeholders to upper case.
// Used for dayMonthYear inputs (DD/MM/YYYY)
.upper::placeholder {
	text-transform: uppercase;
}

// This class will make all the label for all the input fields bolder
.mat-form-field-appearance-legacy .mat-form-field-label,
label {
	font-weight: 600 !important;
}

// This class will not work for inline elements, make sure inline-block is applied to span elements
.text-sentence-case:first-letter {
	text-transform: capitalize;
}

body.app-content {
	background-color: $background-light;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
}

// Fix so that Dropdown inside modals are able to run off the bottom of a modal (ex. Edit Project Status modal)
body {
	position: relative;
	//background-color: white !important;
	background-color: #f5f5f5 !important;
}

// material select
.custom-material-panel {
	margin-top: 24px !important;
	min-width: 100% !important;
	margin-left: 16px;
}

// Truncate to a specific number of lines
.line-limit-1,
.line-limit-2,
.line-limit-3 {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.line-limit-1 {
	-webkit-line-clamp: 1;
}
.line-limit-2 {
	-webkit-line-clamp: 2;
}
.line-limit-3 {
	-webkit-line-clamp: 3;
}

// remove active state from elements
:focus {
	outline: none;
	box-shadow: none;
}

.pointer {
	cursor: pointer;
}

::placeholder {
	color: #a3a3a3 !important;
	text-transform: none;
}

.ng-select.select-custom {
	.ng-select-container {
		border: 1px solid #d1d5db !important;
		border-radius: 6px;
		max-width: 100%;
		overflow: hidden;
	}

	.ng-placeholder {
		font-size: 14px;
		color: #262626;
		text-transform: none !important;
	}

	.ng-dropdown-panel {
		border: 1px solid #e5e7eb !important;
		border-radius: 0 0 6px 6px;
		z-index: 1051;
		position: absolute !important;

		.ng-dropdown-panel-items {
			padding: 6px;

			.ng-option {
				background-color: transparent;
				border-radius: 5px;

				&:hover {
					background-color: #f3f4f6;
				}
			}
		}
	}
}

.ng-select.select-custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
	color: #333;
	background-color: transparent !important;

	&:hover {
		background-color: #f3f4f6 !important;
	}
}

.ng-select .ng-arrow-wrapper .ng-arrow {
	border-color: none !important;
	border-style: none !important;
	border-width: 0 !important;
}

.ng-select .ng-arrow-wrapper {
	background: url('./assets/icons/chevron.svg') no-repeat;
	background-size: 14px 14px;
	margin-top: 2px;
}

// FormControl ERROR Styles because we do not use forms correctly ************
.ng-invalid:not(form):not(.disable-error).ng-touched,
.submitted .ng-invalid:not(.disable-error) {
	box-shadow: 0 0 0 0.2rem rgba($danger, 0.5) !important;
	border-color: rgba($danger, 0.25) !important;
	border-radius: 5px;
}

// hacky override for inactive state
.bg-inactive {
	color: $placeholder;
}

// fix errors caused by content-cards inside of content-cards
.content-card-wrapper .content-card-wrapper {
	padding: 0 !important;
}

.disabled {
	background-color: $disabled-background !important;
	color: $disabled !important;
	pointer-events: none;
}

.pageDescriptionStyles {
	//max-width: 500px;
	padding-left: 2px;
	color: $text-muted;
	font-size: 16px;
	&:first-letter {
		text-transform: capitalize;
	}
}

.disclaimerStyles {
	margin-top: 12px;
	padding-left: 2px;
	font-size: 12px;
	&:first-letter {
		text-transform: capitalize;
	}
}

//For consistancy with input-style
.ng-select .ng-select-container {
	border-color: rgb(209 213 219) !important;
	border-radius: 0.375rem !important;
}

//Primary colour border on all inputs when focussed
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
		border-color: var(--primary-color) !important;
		border-radius: 0.375rem !important;
		border-width: 3px !important;
	}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
	border-color: var(--primary-color) !important;
	border-radius: 0.375rem !important;
	border-width: 3px !important;
}

//.ng-select-focused {
//	border-color: $primary !important;
//	border-radius: 0.375rem !important;
//	border-width: 3px !important;
//}

.input-style {
	border-radius: 0.375rem;
	display: block;
	min-height: 36px;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1.5;
	background-color: #ffffff;
	background-clip: padding-box;
	margin-bottom: 10px;

	.input-style:focus {
		border-color: var(--primary-color) !important;
		border-radius: 0.375rem !important;
		border-width: 3px !important;
	}
}

.mat-focused {
	border-color: var(--primary-color) !important;
	border-radius: 0.375rem !important;
	border-width: 3px !important;
}
input[matInput]:focus {
	box-shadow: none !important;
}

//box-shadow: none !important;


.form-control:focus {
	border-color: var(--primary-color) !important;
	border-radius: 0.375rem !important;
	border-width: 3px !important;
}

.mdc-line-ripple--active {
	border: none !important;

}

//.mat-mdc-form-field-focus {
//	border-color: $primary !important;
//	border-radius: 0.375rem !important;
//	border-width: 3px !important;
//}

// End Input Border Focus


.intercom-messenger-frame {
	left: 60px !important;
	bottom: 8px !important;
}

// Fix required for ng-select dropdowns that use appendTo="body"
// In certain cases the dropdown list appears misaligned with
// the original input.
// Apply ng-select-append-to-body-fix class to any ng-select tags
// that require this fix
.ng-dropdown-panel.ng-select-append-to-body-dropdown-fix {
	margin-left: 15px;
}

.l-mat-autocomplete {
	display: block !important;
	padding: 0 !important;
	background: transparent !important;
	border: 1px solid #cccccc;
	border-radius: 4px;
	.mat-mdc-form-field-infix {
		padding: 4px 8px !important;
		margin-bottom: 4px !important;
		min-height: 17px !important;
	}
	.mat-datepicker-input,
	.mat-date-range-input-inner {
		font-size: 0.8125rem !important;
	}
	.mat-date-range-input-inner::placeholder,
	.mat-date-range-input-separator,
	.mat-input-element::placeholder,
	.mat-datepicker-input::placeholder {
		color: #aab2c0 !important;
		font-size: 0.8125rem !important;
	}

	.mat-mdc-icon-button.mat-mdc-button-base {
		width: 24px;
		height: 24px;
		margin-right: 5px;
		&:hover {
			background: transparent !important;
		}
	}
	.mat-mdc-icon-button {
		color: $dark !important;
	}
	.mat-mdc-form-field-focus-overlay {
		display: none !important;
	}
	.ng-invalid:not(form):not(.disable-error).ng-touched,
	.submitted .ng-invalid:not(.disable-error) {
		background: none !important;
		box-shadow: none !important;
	}
	.mdc-text-field--filled:not(.mdc-text-field--disabled) {
		background: transparent !important;
	}
	.mat-mdc-form-field-subscript-wrapper {
		display: none !important;
	}
	.mdc-line-ripple,
	.mdc-line-ripple--active {
		display: none !important;
	}
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
	background-color: transparent !important;
}

.mat-date-range-input-container,
.mat-date-range-input-separator{
	color: black !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
	width: 40px;
	height: 40px;
}

.mat-mdc-slider {
	.mat-ripple.mat-mdc-focus-indicator {
		display: none;
	}
	.mdc-slider__track--active {
		height: 2px !important;
	}
	.mdc-slider__track--inactive,
	.mdc-slider__track {
		height: 2px !important;
	}
	.mdc-slider__track--active_fill {
		height: var(--slider-height);
		border-top-width: var(--slider-height);
	}
	.mdc-slider__thumb-knob {
		height: 5px !important;
		width: 5px !important;
		border-width: 7px !important;
	}
}

.mat-mdc-option {
	font-size: 13px !important;
	font-family: 'Inter', sans-serif !important;
}

.is-input-disabled {
	.form-control:disabled {
		background: transparent !important;
	}
}

// Overwrite ngx-bootstrap modal default style.
.modal-content {
	border-radius: 6px !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
	background-color: $selectedBackground;
}

.shepherd-button {
	background: var(--primary-color) !important;
	color: white !important;
}

.shepherd-button:not(:disabled):hover {
	background: var(--primary-color2) !important;
}

.shepherd-button.back-button:hover {
	background: #e6e6e6 !important;
	color: rgba(0, 0, 0, 0.75) !important;
}

.shepherd-button.back-button {
	background: #f1f2f3 !important;
	color: rgba(0, 0, 0, 0.75) !important;
}

.shepherd-modal-overlay-container {
	z-index: 1030 !important;
}

.shepherd-content {
	z-index: 999999 !important;
}

.bg-custom-danger {
	background-color: #fef2f2;
	color: #b62f1b !important;
}

.slide-over-table th {
	vertical-align: top;
}

.mos-table th {
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
}

#user-avatar-btn::after {
	content: none !important;
}

.custom-date-range-picker {
	background-color: black;
}

.nav-tabs {
	border-bottom: none !important;
}

// Disable pagewide pinch-zoom so we can have the MT's canvas recieve the zoom event
.app-zoom {
	touch-action: pan-x pan-y;
}